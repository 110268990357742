<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- OFFICERS -->
    <v-navigation-drawer
      v-if="
        appRole == 'range' ||
        appRole == 'division' ||
        appRole == 'EmergencyUser' ||
        appRole == 'RRT'
      "
      v-model="sideNav"
      fixed
      temporary
      width="220px"
      class="hidden-md-and-up"
      color="#FAFAFA"
    >
      <v-layout wrap justify-center>
        <v-flex xs12 text-center>
          <Logo />
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 text-uppercase align-self-center>
          <v-list dense nav>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <!-- ADMIN -->
    <v-navigation-drawer
      v-if="appRole == 'admin'"
      v-model="sideNav"
      fixed
      temporary
      width="280px"
      class="hidden-md-and-up"
      color="#FAFAFA"
    >
      <v-layout wrap justify-center>
        <v-flex xs12 text-center>
          <Logo />
        </v-flex>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex xs6 md6 sm6 lg6 align-self-center class="text-center">
              <v-btn depressed dark  :color="activeSection=='hwc'? 'red' : ''" @click="activeSection = 'hwc'">HWC</v-btn>
            </v-flex>
            <v-flex xs6 md6 sm6 lg6 class="text-center">
              <v-btn depressed dark :color="activeSection=='sarpa'? 'red' : ''" @click="activeSection = 'sarpa'">SARPA</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 text-uppercase align-self-center>
          <v-list dense nav v-if="activeSection === 'hwc'">
            <v-list-item
              v-for="item in adminItems"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
            <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ report.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="report.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in report.reportItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <!-- <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ sarpa.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="sarpa.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in sarpa.sarpaItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group> -->
          </v-list>
          <v-list dense nav v-if="activeSection === 'sarpa'">
            <v-list-item
              v-for="item in sarpadashboard"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <!-- SIDENAV -->
    <v-navigation-drawer
      v-model="sideNavRight"
      right
      fixed
      temporary
      width="220px"
      class="hidden-md-and-up"
      color="#FAFAFA"
    >
      <v-layout wrap>
        <v-flex v-for="(item, i) in headerItems" :key="i" xs12 text-center>
          <v-layout wrap>
            <v-flex
              xs12
              @click="changeRoute(item)"
              :class="
                $route.query.id == item.nameValue
                  ? 'appHeaderActiveClass'
                  : 'appHeaderNormalClass' &&
                    !$route.query.id &&
                    item.nameValue == 'activeCases'
                  ? 'appHeaderActiveClass'
                  : 'appHeaderNormalClass'
              "
              style="
                text-decoration: none;
                cursor: pointer;
                letter-spacing: 0px;
              "
            >
              <span
                :class="
                  $vuetify.breakpoint.name == 'md'
                    ? 'font-size-md'
                    : 'font-size-other'
                "
              >
                {{ item.title }}:
              </span>
              <v-btn
                color="#ededed"
                elevation="0"
                width="40px"
                height="35px"
                fab
                ><span class="pa-1" style="color: #000; font-size: 10px">{{
                  item.value
                }}</span>
              </v-btn>
            </v-flex>
            <v-flex xs12 pt-3>
              <v-progress-linear
                height="5px"
                v-if="
                  $route.query.id == item.nameValue ||
                  (!$route.query.id && item.nameValue == 'activeCases')
                "
                value="100"
                color="#ededed"
              ></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <!-- APPHEADER -->
    <v-app-bar color="#FFFFFF" dense dark class="hidden-md-and-up">
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="#000">mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <form
        class="py-2"
        @submit.prevent="appSearch"
        style="color: red !important; border-color: red !important"
      >
        <v-text-field
          v-model="searchKey"
          append-icon="mdi-magnify"
          @click:append="appSearch"
          placeholder="Search Location"
          light
          height="25"
          color="#808080"
          dense
          outlined
          style="
            border-color: #808080 !important;
            font-size: 14px;
            font-family: sofiaProMedium;
          "
          single-line
          hide-details
        ></v-text-field>
      </form>

      <v-app-bar-nav-icon @click.native="sideNavRight = !sideNavRight">
        <v-icon color="#4D4D4D"> mdi-details </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon>
        <AppNotifications />
      </v-app-bar-nav-icon>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="#000">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card tile flat :elevation="0" max-width="300px">
          <v-layout wrap justify-center py-4>
            <v-flex pt-4 xs12 text-center>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 16px;
                  color: #000000;
                "
              >
                Welcome
              </span>
            </v-flex>
            <v-flex pt-2 xs12 text-center>
              <v-icon color="#CCB4B4" size="30">
                mdi-account-circle-outline
              </v-icon>
            </v-flex>
            <v-flex xs12 text-center v-if="appUserrole === 'admin'">
              <router-link to="/Admin/profile">
                <span
                  v-if="appLogin"
                  style="
                    font-family: poppinsmedium;
                    font-size: 13px;
                    color: #1e1b1b;
                    text-transform: uppercase;
                  "
                >
                  <span>
                    {{ appUser }} </span
                  ><br />
                  <span>{{ appUserrole }}</span>
                </span>
              </router-link>
            </v-flex>
            <v-flex xs12 text-center v-if="appUserrole !== 'admin'">
              <router-link to="/Officers/profile">
                <span
                  v-if="appLogin"
                  style="
                    font-family: poppinsmedium;
                    font-size: 13px;
                    color: #1e1b1b;
                    text-transform: uppercase;
                  "
                >
                  <span>
                    {{ appUser }} </span
                  ><br />
                  <span>{{ appUserrole }}</span>
                </span>
              </router-link>
            </v-flex>
            <v-flex xs12 pt-4 text-center>
              <v-btn
                v-if="!appLogin"
                color="#0000001f"
                outlined
                tile
                small
                :ripple="false"
                to="/login"
              >
                <span
                  style="font-family: poppinssemibold; font-size: 14px"
                  class="appColor"
                >
                  LOGIN
                </span>
              </v-btn>
              <v-btn
                v-if="appLogin"
                color="#0000001f"
                outlined
                tile
                small
                :ripple="false"
                @click="appLogout"
              >
                <span
                  style="font-family: poppinssemibold; font-size: 14px"
                  class="appColor"
                >
                  LOGOUT
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-app-bar
      color="#FFFFFF"
      dense
      flat
      style="padding-left: 0px !important"
      class="hidden-sm-and-down"
      height="60px"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-layout wrap fill-height>
        <v-flex xs7 text-left align-self-center>
          <v-layout wrap>
            <v-flex v-for="(item, i) in headerItems" :key="i" xs3 text-center>
              <v-layout wrap style="margin-bottom: -20px">
                <v-flex
                  xs12
                  @click="changeRoute(item)"
                  :class="
                    $route.query.id == item.nameValue
                      ? 'appHeaderActiveClass'
                      : 'appHeaderNormalClass' &&
                        !$route.query.id &&
                        item.nameValue == 'activeCases'
                      ? 'appHeaderActiveClass'
                      : 'appHeaderNormalClass'
                  "
                  style="
                    text-decoration: none;
                    cursor: pointer;
                    letter-spacing: 0px;
                  "
                >
                  <span
                    :class="
                      $vuetify.breakpoint.name == 'md'
                        ? 'font-size-md'
                        : 'font-size-other'
                    "
                  >
                    {{ item.title }}:
                  </span>
                  <v-btn
                    color="#ededed"
                    elevation="0"
                    width="40px"
                    height="35px"
                    fab
                    ><span class="pa-1" style="color: #000; font-size: 12px">{{
                      item.value
                    }}</span>
                  </v-btn>
                </v-flex>
                <v-flex xs12 pt-3>
                  <v-progress-linear
                    height="5px"
                    v-if="
                      $route.query.id == item.nameValue ||
                      (!$route.query.id && item.nameValue == 'activeCases')
                    "
                    value="100"
                    color="#ededed"
                  ></v-progress-linear>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs5 align-self-center text-left>
          <v-layout wrap>
            <v-flex xs1> </v-flex>
            <!-- <v-flex xs3 align-self-center text-center pr-2>
              <v-layout wrap style="margin-bottom: -20px">
                <v-flex xs12>
                  <router-link
                    :to=" $store.state.userRole == 'admin'
                            ? '/admin/adminDashboard?id=mergeCases'
                            : '/officers/dashboard?id=mergeCases'"
                    :class="
                      $route.query.id == 'mergeCases'
                        ? 'appHeaderActiveClass'
                        : 'appHeaderNormalClass'
                    "
                    style="
                      text-decoration: none;

                      letter-spacing: 0px;
                    "
                  >
                    <span
                   
                      :class="
                        $vuetify.breakpoint.name == 'md'
                          ? 'font-size-md'
                          : 'font-size-other'
                      "
                    >
                      Merge Cases:
                    </span>
                    <v-btn
                      color="#ededed"
                      elevation="0"
                      width="25px"
                      height="23px"
                      fab
                      ><span
                       v-if="$store.state.userRole == 'official'"
                        class="pa-1"
                        style="color: #000; font-size: 10px"
                        >{{ mergeValue }}</span
                      >
                      <span
                       v-else
                        class="pa-1"
                        style="color: #000; font-size: 10px"
                        >0</span
                      >
                    </v-btn>
                  </router-link>
                </v-flex>
                <v-flex xs12 pt-3>
                  <v-progress-linear
                    height="5px"
                    v-if="$route.query.id == 'mergeCases'"
                    value="100"
                    color="#ededed"
                  ></v-progress-linear>
                </v-flex>
              </v-layout>
            </v-flex> -->
            <!-- <v-flex xs2 align-self-center>
              <v-img src="../../assets/logo.png" width="20px"></v-img>
            </v-flex> -->
            <v-flex xs7>
              <form
                @submit.prevent="appSearch"
                style="color: red !important; border-color: red !important"
              >
                <v-text-field
                  v-model="searchKey"
                  append-icon="mdi-magnify"
                  @click:append="appSearch"
                  placeholder="Search Location"
                  light
                  color="#808080"
                  dense
                  outlined
                  style="
                    border-color: #808080 !important;
                    font-size: 14px;
                    font-family: sofiaProMedium;
                  "
                  single-line
                  hide-details
                ></v-text-field>
              </form>
            </v-flex>
            <v-flex xs2 align-self-center text-right>
              <AppNotifications />
            </v-flex>

            <v-flex xs2 align-self-center text-right v-if="appLogin">
              <AccountMenu />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!-- <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="#000">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="n in 5" :key="n" @click="() => {}">
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
  </div>
</template>
<script>
//WILDWATCH
import axios from "axios";
import AccountMenu from "./accountMenu";
import AppNotifications from "@/views/Notifications/appNotification";
import Socket from "./../../Sockets/socket";

export default {
  components: {
    AppNotifications,
    AccountMenu,
  },
  data() {
    return {
      activeSection: 'hwc',
      ServerError: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      searchKey: this.$route.query.searchKey
        ? this.$route.query.searchKey
        : null,
      sideNav: false,
      sideNavRight: false,
      adminItems: [
        {
          title: "Home",
          icon: "mdi-view-dashboard",
          path: "/Admin/admindashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Admin/profile" },
        { title: "Public", icon: "mdi-account-group", path: "/Admin/public" },
        // {
        //   title: "Conflict Reports",
        //   icon: "mdi-source-repository-multiple",
        //   path: "/Admin/ConflictReports",
        // },
        {
          title: "Dashboard",
          icon: "mdi-home-analytics",
          path: "/admin/analytics",
        },
        {
          title: "Statistics ",
          icon: "mdi-chart-bar",
          path: "/admin/commonanalyticspage",
        },
        {
          title: "Add Emergency User",
          icon: "mdi-account-plus",
          path: "/Admin/emergencyUser",
        },
        // {
        //   title: "Notifications",
        //   icon: "mdi-bell",
        //   path: "/Admin/notifications",
        // },
        // {
        //   title: "All Reportings",
        //   icon: "mdi-file-chart",
        //   path: "/Admin/myReport",
        // },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/reportGeneration",
        },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Admin/closedcases",
        },
      ],
      report: {
        title: "Dynamic Data",
        icon: "mdi-folder",
        visible: false,
        reportItems: [
          {
            title: "Scenerios",
            path: "/Admin/dynamicData/scenerios",
          },
          {
            title: "Animals",
            path: "/Admin/dynamicData/animals",
          },
          {
            title: "Animal Activities",
            path: "/Admin/dynamicData/animalActivities",
          },
          {
            title: "Animal Status",
            path: "/Admin/dynamicData/animalStatus",
          },
          {
            title: "Mitigations",
            path: "/Admin/dynamicData/mitigationTypes",
          },
          {
            title: "Land Types",
            path: "/Admin/dynamicData/landTypes",
          },
          {
            title: "Gender",
            path: "/Admin/dynamicData/gender",
          },
          {
            title: "Breach Types",
            path: "/Admin/dynamicData/breachType",
          },
          {
            title: "Barrier Types",
            path: "/Admin/dynamicData/barrierType",
          },
          {
            title: "Conflict Types",
            path: "/Admin/dynamicData/conflictType",
          },
          {
            title: "Crop Types",
            path: "/Admin/dynamicData/cropType",
          },
        ],
      },
      sarpadashboard: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Sarpa/dashboard",
        },
        {
          title: "Add Snakes",
          icon: "mdi-view-dashboard",
          path: "/addSnakes",
        },
        {
          title: "All Users",
          icon: "mdi-view-dashboard",
          path: "/usersList",
        },
        {
          title: "New Rescuers",
          icon: "mdi-view-dashboard",
          path: "/newrescuerslist",
        },
        {
          title: "Rescuer List",
          icon: "mdi-view-dashboard",
          path: "/allrescuerlist",
        },
        {
          title: "Pending Operations",
          icon: "mdi-view-dashboard",
          path: "/pendingoperations",
        },
        {
          title: "Assigned Operations",
          icon: "mdi-view-dashboard",
          path: "/assignedoperations",
        },
        {
          title: "Rescued Operations",
          icon: "mdi-view-dashboard",
          path: "/rescuedoperations",
        },
        {
          title: "Released Operations",
          icon: "mdi-view-dashboard",
          path: "/releasedoperations",
        },
        {
          title: "Approved Operations",
          icon: "mdi-view-dashboard",
          path: "/approvedoperations",
        },
        {
          title: "Delete Operations",
          icon: "mdi-view-dashboard",
          path: "/deleteoperations",
        },
        {
          title: "Training List",
          icon: "mdi-view-dashboard",
          path: "/traininglist",
        },
        {
          title: "Snake Reports",
          icon: "mdi-view-dashboard",
          path: "/snakereports",
        },
        {
          title: "Add Snake Experts",
          icon: "mdi-view-dashboard",
          path: "/addsnakeexperts",
        },
        {
          title: "Add Hospital",
          icon: "mdi-view-dashboard",
          path: "/addhospital",
        },
        {
          title: "View Hospital",
          icon: "mdi-view-dashboard",
          path: "/Viewhospital",
        },
        {
          title: "Identification Help",
          icon: "mdi-view-dashboard",
          path: "/identificationhelp",
        },
        // {
        //   title: "Approve Hospital",
        //   icon: "mdi-view-dashboard",
        //   path: "/approvehospital",
        // },
      ],

      dynamic: [
        {
          title: "Dynamic Data",
          icon: "mdi-folder",
          visible: false,
          items: [
            {
              title: "Scenerios",
              path: "/Admin/dynamicData/scenerios",
            },
            {
              title: "Animals",
              path: "/Admin/dynamicData/animals",
            },
            {
              title: "Animal Activities",
              path: "/Admin/dynamicData/animalActivities",
            },
            {
              title: "Animal Status",
              path: "/Admin/dynamicData/animalStatus",
            },
            // {
            //   title: "Animal Age",
            //   path: "/Admin/dynamicData/animalAge",
            // },
            // {
            //   title: "Veterinary Recommendation",
            //   path: "/Admin/dynamicData/veterinaryRecommendation",
            // },
            {
              title: "Mitigations",
              path: "/Admin/dynamicData/mitigationTypes",
            },
            {
              title: "Land Types",
              path: "/Admin/dynamicData/landTypes",
            },
            {
              title: "Gender",
              path: "/Admin/dynamicData/gender",
            },
            // {
            //   title: "Close Status",
            //   path: "/Admin/dynamicData/closeStatus",
            // },
            // {
            //   title: "Release Types",
            //   path: "/Admin/dynamicData/releaseTypes",
            // },
            {
              title: "Breach Types",
              path: "/Admin/dynamicData/breachType",
            },
            {
              title: "Barrier Types",
              path: "/Admin/dynamicData/barrierType",
            },
            {
              title: "Conflict Types",
              path: "/Admin/dynamicData/conflictType",
            },
            {
              title: "Crop Types",
              path: "/Admin/dynamicData/cropType",
            },
          ],
        },
      ],
      sarpa: {
        title: "SARPA",
        icon: "mdi-snake",
        visible: false,
        sarpaItems: [
          {
            title: "Dashboard",
            path: "/Sarpa/dashboard",
          },
          {
            title: "Add Snakes",
            path: "/addSnakes",
          },
          {
            title: "All Users",
            path: "/usersList",
          },
          {
            title: "New Rescuers",
            path: "/newrescuerslist",
          },
          {
            title: "Rescuer List",
            path: "/allrescuerlist",
          },
          {
            title: "Pending Operations",
            path: "/pendingoperations",
          },
          {
            title: "Assigned Operations",
            path: "/assignedoperations",
          },
          {
            title: "Rescued Operations",
            path: "/rescuedoperations",
          },
          {
            title: "Released Operations",
            path: "/releasedoperations",
          },
          {
            title: "Approved Operations",
            path: "/approvedoperations",
          },
          {
            title: "Delete Operations",
            path: "/deleteoperations",
          },
          {
            title: "Training List",
            path: "/traininglist",
          },
          {
            title: "Snake Reports",
            path: "/snakereports",
          },
          {
            title: "Add Snake Experts",
            path: "/addsnakeexperts",
          },
          {
            title: "Add Hospital",
            path: "/addhospital",
          },
          {
            title: "View Hospital",
            path: "/Viewhospital",
          },
          {
            title: "Identification Help",
            path: "/identificationhelp",
          },
          {
            title: "Approve Hospital",
            path: "/approvehospital",
          },
        ],
      },
      // items: [
      //   {
      //     title: "Dashboard",
      //     icon: "mdi-view-dashboard",
      //     path: "/Officers/dashboard",
      //   },
      //   {
      //     title: "My Details",
      //     icon: "mdi-account",
      //     path: "/Officers/myDetails",
      //   },
      //   { title: "Teams", icon: "mdi-account-group", path: "/Officers/teams" },
      //   { title: "Forms", icon: "mdi-form-select", path: "/Officers/forms" },
      //   {
      //     title: "Notifications",
      //     icon: "mdi-bell",
      //     path: "/Officers/notifications",
      //   },
      //   {
      //     title: "Officers",
      //     icon: "mdi-account-circle",
      //     path: "/Officers/officers",
      //   },
      //   {
      //     title: "Merge Cases",
      //     icon: "mdi-call-merge",
      //     path: "/Officers/mergeCases",
      //   },
      //   {
      //     title: "Analytics",
      //     icon: "mdi-home-analytics",
      //     path: "/Officers/analytics",
      //   },
      // ],
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Officers/dashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Officers/profile" },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/Officers/reportGenerationofficers",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Officers/notifications",
        },
        {
          title: "Report Case",
          icon: "mdi-file-eye",
          path: "/Officers/reportcase",
        },
        // {
        //   title: "Merge Cases",
        //   icon: "mdi-call-merge",
        //   path: "/Officers/mergeCases",
        // },
      ],
      headerItems: [
        {
          title: "Active Cases",
          value: 0,
          path: "/?id=activeCases",
          nameValue: "activeCases",
        },

        {
          title: "Assigned Cases",
          value: 0,
          path: "/?id=assignedCases",
          nameValue: "assignedCases",
        },
        {
          title: "Resolved Cases",
          value: 0,
          path: "/?id=resolvedCases",
          nameValue: "resolvedCases",
        },
        {
          title: "Released Cases",
          value: 0,
          path: "/?id=releasedCases",
          nameValue: "releasedCases",
        },
      ],
      analytics: {},
      mergeValue: null,

      // socket Variables
      // role: null,
      socketType: null,
      newData: {},
      activeCasesvalue: "",
      assignedCasesvalue: "",
      resolvedCasesvalue: "",
    };
  },
  beforeMount() {
    if (this.searchKey) this.appSearch();
    else this.getData();
  },
  mounted() {
    this.socketData();
    if (this.appRole == "admin") {
      console.log("first", this.newData);
      this.assignAnalytics(this.newData);
    }
    // this.getSearch()
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.userName;
    },
    appUserrole() {
      return this.$store.state.userRole;
    },
    appRole() {
      return this.$store.state.userRole;
    },
    counterData() {
      return this.$store.state.counterData;
    },
    appNotification() {
      if (this.$store.state.notification == undefined) {
        return 0;
      } else {
        return this.$store.state.notification;
      }
    },
  },
  watch: {
    counterData: {
      handler: function () {
        // console.log(this.storage);
        this.assignAnalytics(this.counterData);
      },
      deep: true,
    },
    // searchKey() {
    //   this.assignAnalytics(this.counterData);
    // },
    // role() {
    //   if (this.role == "official") {
    //     this.assignAnalytics(this.newData)
    //   }
    // },
    //  socketType() {
    //   if (this.socketType == "resolveReport"|| this.socketType == "reportRelease") {
    //     this.assignAnalytics(this.newData)
    //   }
    // },
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },
  methods: {
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
    resetMap() {
      this.searchKey = "";
      this.$route.query.searchKey = null ;
      // window.location.reload();
    },
    // loadData() {
    //   var newData = {};
    //   newData = this.counterData;
    //   console.log(newData, "newData");
    //   this.assignAnalytics(newData);
    // },

    assignAnalytics(analytics) {
      if(analytics.activeCases > 0){
      this.headerItems[0].value = analytics.activeCases;
      }
      else{
      this.headerItems[0].value = 0;
      }
      if(analytics.assignedCases > 0){
        this.headerItems[1].value = analytics.assignedCases;
      }
      else{
        this.headerItems[1].value = 0;
      }
      if(analytics.resolvedCases > 0){
      this.headerItems[2].value = analytics.resolvedCases;
      }
      else{
        this.headerItems[2].value = 0;
      }
      if(analytics.releasedCases > 0){
      this.headerItems[3].value = analytics.releasedCases;
      }
      else{
        this.headerItems[3].value = 0;
      }
    },
    socketData() {
      Socket.countFunction(this); // <---read data
    },
    //WILDWATCH
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/dashboard",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.analytics = response.data.count;
            this.$store.commit("counterData", response.data.count);
            this.assignAnalytics(this.analytics);
            this.$store.commit(
              "changeNotification",
              response.data.unreadNotification
            );
            this.mergeValue = this.analytics.mergeSuggestion;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;

          this.appLoading = false;
          console.log(err);
        });
    },
    getDatasearchKey() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/dashboard",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data:{
          searchKey: this.searchKey
        }
        
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.analytics = response.data.count;
            this.$store.commit("counterData", response.data.count);
            this.assignAnalytics(this.analytics);
            this.$store.commit(
              "changeNotification",
              response.data.unreadNotification
            );
            this.mergeValue = this.analytics.mergeSuggestion;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;

          this.appLoading = false;
          console.log(err);
        });
    },
    changeRoute(item) {
      if (this.$store.state.userRole == "admin") {
        this.$router.push({
          path: "/admin/adminDashboard",
          query: {
            id: this.$route.query.id != item.nameValue ? item.nameValue : null,
            searchKey: this.$route.query.searchKey,
          },
        });
      } else if (
        this.$store.state.userRole == "official" ||
        this.$store.state.userRole == "range" ||
        this.$store.state.userRole == "division" ||
        this.$store.state.userRole == "EmergencyUser" ||
        this.$store.state.userRole == "RRT"
      ) {
        this.$router.push({
          path: "/officers/dashboard",
          query: {
            id: this.$route.query.id != item.nameValue ? item.nameValue : null,
            searchKey: this.$route.query.searchKey,
          },
        });
      }
    },
    appSearch() {
      if (this.$route.query.searchKey == this.searchKey) return;
      this.$router.push({
        path: "",
        query: {
          id: this.$route.query.id,
          searchKey: this.searchKey,
        },
      });
      this.getDatasearchKey();
    },
  },
};
</script>

<style>
.appHeaderActiveClass {
  font-family: sofiaProSemiBold;
  color: #000 !important;
}
.appHeaderNormalClass {
  font-family: sofiaProRegular;
  color: #838383 !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}

.font-size-md {
  font-size: 12px;
}
.font-size-other {
  font-size: 16px;
}
</style>

